import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import ability from "@/libs/acl/ability";
import { debounce } from "lodash";

export default function useList() {
  const refListTable = ref(null);

  const toast = useToast();

  const { t, tc } = useI18nUtils();

  const trans = {
    "actions.download": t("actions.download"),
    "messages.export.Generating_file": t("messages.export.Generating_file"),
    "messages.export.download_succesfully": t("messages.export.download_succesfully"),
    "country.title": tc("country.title", 1),
    report_of: t("messages.export.report"),
    full_download: t("messages.export.full_download"),
  };

  const tableColumns = [
    { label: "Id", key: "id", sortable: true },
    { label: "country.title", key: "name", sortable: true },
    { label: "country.code", key: "code", sortable: true },
    { label: "country.telephone_code", key: "telephone_code", sortable: true },
    { label: "country.region_count", key: "regions_count", sortable: true },
    {
      label: "country.created",
      key: "created_at",
      sortable: true,
      formatter: "formatDateAssigned",
    },
    {
      label: "country.updated",
      key: "updated_at",
      sortable: true,
      formatter: "formatDateAssigned",
    },
  ];
  if (ability.can("update", "Country") || ability.can("delete", "Country")) {
    tableColumns.splice(0, 0, { label: "actions.name", key: "actions" });
  }

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const action = ref("list");
  const buttonExport = ref(false);
  const loadingToast = ref(null);

  const activeFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  const fetchList = (ctx, callback) => {
    if (action.value == "export") {
      buttonExport.value = true;
      loadingToast.value.show(
        trans["actions.download"],
        trans["messages.export.Generating_file"],
        "secondary"
      );
    }

    store
      .dispatch("country/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        active: activeFilter.value,
        action: action.value,
      })
      .then((response) => {
        if (action.value == "export") {
          action.value = "list";
          loadingToast.value.show(
            trans["actions.download"],
            trans["messages.export.download_succesfully"],
            "secondary"
          );

          function id() {
            return store.getters["country/getIdNotification"];
          }
          function url() {
            return store.getters["country/getUrl"];
          }

          const notification = {
            id: id(),
            progress: 100,
            completed: true,
            view: "countries",
            url: url(),
            download: id(),
            title: `${trans["report_of"]} ${trans["country.title"]}`,
            body: trans["full_download"],
            error: false,
            timestamp:
              new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
          };
          store.dispatch("grid/updateDownloadNotification", notification);
        } else {
          callback(response.data);
          totalList.value = response.meta.total;
        }
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error obteniendo la información",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        buttonExport.value = false;
        loadingToast.value.close();
      });
  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch([ activeFilter, currentPage, perPage ], () => {
    refetchData();
  });

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    activeFilter,
    refetchData,
    action,
    buttonExport,
    loadingToast,
  };
}
